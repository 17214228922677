<template>
	<div class="Brand">
		
		<div class="Top">
			<div class="Right">
				<span style="margin-left: 10px;">
					<label>状态</label>
					<el-select v-model="Search.Status" size="mini">
						<el-option :value="''" label="全部状态"></el-option>
						<el-option :value="50" label="待上架"></el-option>
						<el-option :value="55" label="待审核上架"></el-option>
						<el-option :value="60" label="上架中"></el-option>
					</el-select>
				</span>
				<span style="margin-left: 10px;">
					<label>商品类型</label>
					<el-select v-model="Search.IsPt" size="mini">
						<el-option :value="''" label="全部类型"></el-option>
						<el-option :value="1" label="拼团商品"></el-option>
						<el-option :value="2" label="普通商品"></el-option>
					</el-select>
				</span>
				<span style="margin-left: 10px;margin-right: 10px;">
					<label>店铺编号</label>
					<el-input size="mini" v-model="Search.ShopId"></el-input>
				</span>
				<span style="margin-left: 10px;margin-right: 10px;">
					<label>商品编号</label>
					<el-input size="mini" v-model="Search.Id"></el-input>
				</span>
				<span style="margin-left: 10px;">
					<label>关键词</label>
					<el-input size="mini" v-model="Search.Title"></el-input>
				</span>
				<span style="margin-left: 10px;">
					<label>星级</label>
					<el-input size="mini" v-model="Search.Star"></el-input>
				</span>
				
				<span style="margin-left: 10px;">
					<label>一级分类</label>
					<el-select @change="CategoryOneChange" v-model="Search.CategoryOne" size="mini">
						<el-option :value="''" label="全部"></el-option>
						<el-option v-for="(cat,catI) in CategoryListOne" :value="cat.Id" :label="cat.Name" :key="catI"></el-option>
					</el-select>
				</span>
				
				<span style="margin-left: 10px;">
					<label>二级分类</label>
					<el-select @change="CategoryTwoChange" v-model="Search.CategoryTwo" size="mini">
						<el-option :value="''" label="全部"></el-option>
						<el-option v-for="(cat,catI) in CategoryListTwo" :value="cat.Id" :label="cat.Name" :key="catI"></el-option>
					</el-select>
				</span>
				
				<span style="margin-left: 10px;">
					<label>三级分类</label>
					<el-select v-model="Search.CategoryThree" size="mini">
						<el-option :value="''" label="全部"></el-option>
						<el-option v-for="(cat,catI) in CategoryListThree" :value="cat.Id" :label="cat.Name" :key="catI"></el-option>
					</el-select>
				</span>
				
				<span>
					<label style="color: rgba(0,0,0,0);">搜索</label>
					<el-button size="mini" type="danger" @click="StartSearching()">筛选商品</el-button>
				</span>
			</div>
		</div>
		
		<div style="padding: 5px 10px;margin-top:10px;font-size: 1.1rem;background-color: rgba(0,0,0,0.04);color: rgba(0,0,0,0.4);">
			商品列表
		</div>
		
		<div class="List">
			<div class="Title">
				<div class="C1">
					<el-checkbox @change="changeSelectedAll" v-model="SelectedAll"><label  style="font-size: 0.85rem;color: rgba(0,0,0,0.4);">{{SelectedAll ? '全消':'全选'}}</label></el-checkbox></div>
				<div class="C2">商品名</div>
				<div class="C3">商品状态</div>
				<div class="C4">价格</div>
				<div class="C4">类型</div>
				<div class="C5">发布时间</div>
				<div class="C6">操作</div>
			</div>
			
			<div class="One" v-for="item in GoodsList" :key="item.Id">
				<li>
					<div class="C1">
						<el-checkbox @change="changeSelected" v-model="item.IsSelected"></el-checkbox>
						<img v-if="item.Thumb != ''" :src="item.Thumb" />
					</div>
					<div class="C2">{{item.Title}}</div>
					<div class="C3">
						
						{{item.StatusName}}
						
					</div>
					<div class="C4">
						￥{{item.Price}} /<i style="text-decoration: line-through;">{{item.OriginalPrice}}</i>
					
					</div>
					<div class="C4">
						<template v-if="item.IsPt == 2">常规</template>
						<template v-else-if="item.IsPt == 1">拼团</template>
					</div>
					<div class="C5">{{item.CreatedAt}}</div>
					<div class="C6">
						<el-button size="mini" type="primary" @click="$Jump('/do/goods/goods_info/'+item.Id)">商品管理</el-button>
					</div>
				</li>
			</div>
			
		</div>
		
		<div class="Bot">
			
			<div class="Batch">
				<span style="padding-left: 10px;">
					<el-checkbox @change="changeSelectedAll" v-model="SelectedAll">{{SelectedAll ? '全消':'全选'}}</el-checkbox>
				</span>
				<ul>
				<div>
					<el-button size="mini" @click="BatchDialogOpen('category')">批量分类</el-button>
				</div>
				<div>
					<el-button size="mini" @click="BatchStatusUpdate(60)">批量上架</el-button>
				</div>
				<div>
					<el-button size="mini" @click="BatchStatusUpdate(80)">批量下架</el-button>
				</div>
				</ul>
			</div>
			
			<div class="Page">
				<el-pagination
				  background
				  layout="total, sizes, prev, pager, next, jumper"
				  :page-sizes="[15, 30, 50, 100, 200]"
				  @size-change="handleSizeChange"
				  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
			
		</div>
		
		<el-dialog
		  title="商品分类批量设置"
		  :visible="DialogType == 'category'"
		  width="400px"
		  :before-close="DialogClose">

		  <div class="BatchCategorySet">
			  <span style="margin-left: 10px;">
			  	<label>一级分类</label>
			  	<el-select @change="CategoryOneChange" v-model="BatchCategory.One" size="mini">
			  		<el-option v-for="(cat,catI) in BatchCategoryListOne" :value="cat.Id" :label="cat.Name" :key="catI"></el-option>
			  	</el-select>
			  </span>
			  
			  <span style="margin-left: 10px;">
			  	<label>二级分类</label>
			  	<el-select @change="CategoryTwoChange" v-model="BatchCategory.Two" size="mini">
			  		<el-option v-for="(cat,catI) in BatchCategoryListTwo" :value="cat.Id" :label="cat.Name" :key="catI"></el-option>
			  	</el-select>
			  </span>
			  
			  <span style="margin-left: 10px;">
			  	<label>三级分类</label>
			  	<el-select v-model="BatchCategory.Three" size="mini">
			  		<el-option v-for="(cat,catI) in BatchCategoryListThree" :value="cat.Id" :label="cat.Name" :key="catI"></el-option>
			  	</el-select>
			  </span>
			  
			  <span style="margin-left: 10px;">
			  	<label></label>
			  	<el-button size="medium" type="primary" @click="BatchCategoryUpdate()">批量设置分类</el-button>
			  </span>
			  
		  </div>
		  
		</el-dialog>

	</div>
</template>

<script>
	import {Select,Option,Checkbox,Dropdown,DropdownMenu,DropdownItem,Pagination,Dialog} from 'element-ui'
	export default {
	  name: 'GoodsList',
	  props: {
	  },
	  data() {
	      return {
			  SelectedAll:false,
			  Search:{
				  ShopName:'',
				  Source:'',//全部
				  ShopId:'',
				  Id:'',
				  Title:'',
				  Star:'',
				  Status:'',//10已创建 50 上线中 60销售中
				  CategoryOne:'',
				  CategoryTwo:'',
				  CategoryThree:'',
				  IsPt:'',
			  },
			  CategoryListOne:[],
			  CategoryListTwo:[],
			  CategoryListThree:[],
			  GoodsList:[],
			  Page:1,
			  PageSize:15,
			  Total:0,
			  DialogType:'', //分类category status-on status-off star search-update search-remove
			  BatchIds:[],
			  BatchCategoryListOne:[],
			  BatchCategoryListTwo:[],
			  BatchCategoryListThree:[],
			  BatchCategory:{ //批量设置时选择的分类
				  One:'',
				  Two:'',
				  Three:''
			  }
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-checkbox':Checkbox,
		'el-dialog':Dialog,
	  },
	  created() {
		this.GetCategoryList(1,'')
	  	this.StartSearching()
	  },
	  methods:{
		  BatchDialogOpen(_type){
			  let count = 0
			  for(var i=0;i<this.GoodsList.length;i++){
				if(this.GoodsList[i].IsSelected){
					count += 1
				}
			  }
			  if(count == 0){
				  this.$message('请至少选择一个商品后再批量设置')
				  return
			  }
			  if(_type == 'category'){
				  this.BatchCategoryListOne = JSON.parse(JSON.stringify(this.CategoryListOne))
			  }
			  this.DialogType = _type
		  },
		  DialogClose(){
			  this.DialogType = ''
		  },
		  CategoryOneChange(_categoryOne){
			  console.log('类型',this.DialogType)
			 if(this.DialogType != 'category'){ //非列表商品分类批量重设模式
				 this.BatchCategory.Two = ""
				 this.BatchCategoryListTwo = []
				 this.BatchCategory.Three = ""
				 this.BatchCategoryListThree = []
			 }else{
				 this.Search.CategoryTwo = ""
				 this.CategoryListTwo = []
				 this.Search.CategoryThree = ""
				 this.CategoryThree = []
			 }
			
		  	//读取当前一级分类下的所有二级分类
		  	this.GetCategoryList(2,_categoryOne)
		  },
		  CategoryTwoChange(_cid){
			  if(this.DialogType != 'category'){ //非列表商品分类批量重设模式
			  		this.Search.CategoryTwo = _cid
			  		this.Search.CategoryThree = ""
			  		this.CategoryThree = []
			  }else{
					 this.BatchCategory.Two = _cid
					 this.BatchCategory.Three = ""
					 this.BatchCategoryListThree = []
			  }
		  	this.GetCategoryList(3,_cid)
		  },
		  changeSelected(_val){
			 for(var i=0;i<this.GoodsList.length;i++){
				if(this.GoodsList[i].IsSelected == false){
					this.SelectedAll = false
					break
				}
			 }
		  },
		  changeSelectedAll(_val){
			 for(var i=0;i<this.GoodsList.length;i++){
				 this.GoodsList[i].IsSelected = _val
			 }
		  },
		  handleSizeChange(_val){
			this.PageSize = _val
			this.Page = 1
			this.StartSearching()
		  },
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  StartSearching(){
			  this.GetCount()
			  this.GetList(this.Page)
		  },
		  GetList(_page){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Goods',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
					Id:this.Search.Id,
					ShopId:this.Search.ShopId,
					Source:this.Search.Source,
					Title:this.Search.Title,
					Status:this.Search.Status,
					IsPt:this.Search.IsPt,
					Star:this.Search.Star,
					CategoryOne:this.Search.CategoryOne,
					CategoryTwo:this.Search.CategoryTwo,
					CategoryThree:this.Search.CategoryThree,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					let GoodsList = res.Data.GoodsList
					for(var i=0;i<GoodsList.length;i++){
						
						let isSel = false
						if(this.BatchIds.length > 0){
							for(var j=0;j<this.BatchIds.length;j++){
								if(this.BatchIds[j] == GoodsList[i].Id){
									isSel = true
								}
							}
						}
						GoodsList[i].IsSelected = isSel
						
					}
					this.GoodsList = GoodsList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Goods',
		  			Action: 'Count',
		  			Id:this.Search.Id,
					IsPt:this.Search.IsPt,
		  			ShopId:this.Search.ShopId,
		  			Source:this.Search.Source,
		  			Title:this.Search.Title,
		  			Status:this.Search.Status,
					Star:this.Search.Star,
					CategoryOne:this.Search.CategoryOne,
					CategoryTwo:this.Search.CategoryTwo,
					CategoryThree:this.Search.CategoryThree,
		  		}
		  
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetCategoryList(_level,_fatherId){
		  	
		  		let data = {
		  			Service:'Goods',
		  			Class: 'GoodsCategory',
		  			Action: 'List',
		  			Level:_level,
		  			Page:1,
		  			PageSize:300,
		  			FatherId:_fatherId,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			
					 if(this.DialogType != 'category'){
						 if(_level == 1){
						 	this.CategoryListOne = res.Data.CategoryList
						 }else if(_level == 2){
						 	this.CategoryListTwo = res.Data.CategoryList
						 }else if(_level == 3){
						 	this.CategoryListThree = res.Data.CategoryList
						 }
					 }else{
						 if(_level == 2){
						 	this.BatchCategoryListTwo = res.Data.CategoryList
						 }else if(_level == 3){
						 	this.BatchCategoryListThree = res.Data.CategoryList
						 }
					 }
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  BatchStatusUpdate(_status){
		  	
				let tempIds = []
				for(var i=0;i<this.GoodsList.length;i++){
					if(this.GoodsList[i].IsSelected){
						tempIds.push(this.GoodsList[i].Id)
					}
				}
				if(tempIds.length == 0){
					this.$message('请至少选择一个商品')
					return
				}
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Goods',
		  			Action: 'BatchStatus',
		  			Status:_status,
		  			Ids:JSON.stringify(tempIds),
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
					
					this.$message(res.Msg)
					
		  			if(res.ErrorId != 0){
		  				return
		  			}
					this.BatchIds = tempIds
					this.GetList()
					this.DialogType = ''
		  		
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  BatchCategoryUpdate(){
		  	
				let tempIds = []
				for(var i=0;i<this.GoodsList.length;i++){
					if(this.GoodsList[i].IsSelected){
						tempIds.push(this.GoodsList[i].Id)
					}
				}
		  		if(tempIds.length == 0){
					this.$message('请至少选择一个商品')
					return
				}
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Goods',
		  			Action: 'BatchCategory',
		  			CategoryOne:this.BatchCategory.One,
					CategoryTwo:this.BatchCategory.Two,
					CategoryThree:this.BatchCategory.Three,
		  			Ids:JSON.stringify(tempIds),
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  					
		  			this.$message(res.Msg)
		  					
		  			if(res.ErrorId != 0){
		  				return
		  			}
					this.BatchIds = tempIds
					this.GetList()
					this.DialogType = ''
		  		
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
	  }
	}
</script>

<style scoped>
.Brand{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Brand .Top{
	display: flex;
	align-items: center;
}
.Brand .Top .Left{
	
}
.Brand .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	flex-wrap: wrap;
}
.Brand .Top .Right span{
	margin-left: 10px;
	margin-bottom: 5px;
}
.Brand .Top .Right label{
	display: block;
	font-size: 0.9rem;
	margin-bottom: 5px;
}
.Brand .List{
}
.C1,.C2,.C3,.C4,.C5,.C6{
	width: 150px;
}
.C1{
	padding-left: 10px;
	width: 85px;
	margin-right: 20px;
	display: flex;
	align-items: center;
}
.C1 img{
	width: 60px;
	margin-left: 5px;
}
.C2{
	flex: 1;
	margin-right: 10px;
}
.C4 i{
	font-style: normal;
	color: rgba(0,0,0,0.3);
	margin-left: 5px;
}
.C5{
	color: rgba(0,0,0,0.3);
}
.C6{
	padding-right: 10px;
	text-align: right;
}
.Brand .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.Brand .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.Brand .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.Brand .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}

.Batch{
	display: flex;
	align-items: center;
	padding: 10px 0px;
}
.Batch ul{
	flex: 1;
	display: flex;
	justify-content: flex-end;
}
.Batch div{
	margin-left: 10px;
}
.BatchCategorySet{
	
}
.BatchCategorySet span{
	display: flex;
	width: 100%;
	margin-bottom: 10px;
}
.BatchCategorySet span label{
	display: flex;
	width: 80px;
}
</style>
